import request from "./index";
export const login = (parm) => {
    return request({
        url: 'checkticket/getToken'
    })
}
export const qushi = (parm) => {
    return request({
        url: 'checkticket/qushi?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}
export const qushinum = (parm) => {
    return request({
        url: 'checkticket?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}

export const rijunthisyear = (date) => {
    return request({
        url: '/saledata/rijunthisyear/' + date
    })
}

export const gender = (parm) => {
    return request({
        url: '/saledata/gender?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}

export const salemethod = (parm) => {
    return request({
        url: '/saledata/salemethod?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}

export const age = (parm) => {
    return request({
        url: '/saledata/age?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}

export const getItemType = (parm) => {
    return request({
        url: '/saledata/itemType?starTime='+parm.starTime+'&&endTime='+parm.endTime
    })
}