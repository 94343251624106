import axios from 'axios';

const API_BASE_URL = 'https://utp10e.laf.run'; // 替换为你的API基础URL

const api = {
  //获取票务数据
  getTicket() {
    return axios.get(`${API_BASE_URL}/ticket`);
  },
  //获取接待人数数据
  getRepeole() {
    return axios.get(`${API_BASE_URL}/statistics_reception_volume`)
  },
  //获取接待趋势
  getRetrends() {
    return axios.get(`${API_BASE_URL}/tourist_reception_trends`)
  },
  //获取年龄趋势
  getAgetrends() {
    return axios.get(`${API_BASE_URL}/tourist_age_trends`)
  },
  //获取航班时刻表
  getflight() {
    return axios.get(`${API_BASE_URL}/flight_schedule`)
  },
  //获取停车位管理
  getparking() {
    return axios.get(`${API_BASE_URL}/parking_space_management`)
  },
  //获取全球眼监控
  getglobaleye() {
    return axios.get(`${API_BASE_URL}/global_eye_monitor`)
  },
  //获取车辆进出监控视频
  getcar_video() {
    return axios.get(`${API_BASE_URL}/car_entry_exit_video`)
  },

  //获取车辆营收统计
  getparking_revenue() {
    return axios.get(`${API_BASE_URL}/parking_revenue`)
  },
  //获取营收趋势
  getrevenue_trends() {
    return axios.get(`${API_BASE_URL}/revenue_trends`)
  },
  //获取今日车辆数据统计
  getcarstatistics() {
    return axios.get(`${API_BASE_URL}/today_car_statistics`)
  },
  //获取重点区域视频监控
  getkey_areas_video() {
    return axios.get(`${API_BASE_URL}/key_areas_video`)
  },
  //获取今日车位统计
  getparking_statistics() {
    return axios.get(`${API_BASE_URL}/today_parking_statistics`)
  },
  //获取车流量趋势
  getcar_flow_trends() {
    return axios.get(`${API_BASE_URL}/car_flow_trends`)
  },
  //车位利用趋势
  getparking_space_use_rate_trends() {
    return axios.get(`${API_BASE_URL}/parking_space_use_rate_trends`)

  },
  gettoday_visitor_statistics() {
    return axios.get(`${API_BASE_URL}/today_visitor_statistics`)

  },
  //船主船员信息
  getcrewinfo() {
    return axios.get(`${API_BASE_URL}/owner_and_crew_information`)
  },
  //预警信息栏
  getwarninginfo() {
    return axios.get(`${API_BASE_URL}/warning_information_bar`)
  },
  //安检设备在线状态
  getsecurity_online() {
    return axios.get(`${API_BASE_URL}/security_device_online_status`)
  },
  //区域报警排名
  getarea_alaram() {
    return axios.get(`${API_BASE_URL}/area_alarm_rank`)
  },
  //访客趋势折线图
  getvisitor_trends() {
    return axios.get(`${API_BASE_URL}/visitor_trends`)
  }


}
export default {
  install(Vue) {
    Vue.prototype.$api = api;
  }
}
  // createUser(userData) {
  //   return axios.post(`${ API_BASE_URL } / users`, userData);
  // },
  // updateUser(userId, userData) {
  //   return axios.put(`${ API_BASE_URL } / users / ${ userId }`, userData);
  // },
  // deleteUser(userId) {
  //   return axios.delete(`${ API_BASE_URL } / users / ${ userId }`);
  // }

