<script >
export default {
  props: {
    // 适配器尺寸宽
    width: {
      type: Number,
      default: 1920
    },
    // 适配器尺寸高
    height: {
      type: Number,
      default: 1080
    },
    // 是否启用适配器
    isEnable: {
      type: Boolean,
      default: true
    }
  },
    mounted() {
    if (this.$props.isEnable) {
      window.addEventListener('resize', this.scale)
      // app.style.transform = `translate(-50%, -50%)`
      this.scale()
    }

    app.style.width = this.$props.width + 'px'
    app.style.height = this.$props.height + 'px'
  },
  methods: {
    scale() {
      const w = window.innerWidth
      const h = window.innerHeight
      const pw = w / this.$props.width
      const ph = h / this.$props.height

      document.body.style.width = w + 'px'
      document.body.style.height = h + 'px'
      app.className = 'auto'

      // 宽大于高
      if (pw > ph) {
        app.style.transform = `scale(${ph}, ${ph}) translate(-50%, -50%)`
      } else {
        app.style.transform = `scale(${pw}, ${pw}) translate(-50%, -50%)`
      }
    }
  },

};





</script>

<template>
  <div>
     <slot />
  </div>
 
</template>

<style lang="less">
#app {
  margin: 0 auto;
  transform-origin: left top;
  transform: translate(-50%, -50%);

  &.auto {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 100;
    overflow: hidden;
    transition-duration: 0.3s;
  }
}
</style>
