import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/css/global.less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import echartsGL from 'echarts-gl'
import apiPlugin from '../src/api/api.js';
// import lunduapi from '../src/api/lunduapi'
import * as echarts from 'echarts'
import dataV from '@jiaminghi/data-view/lib/index'
import '@/assets/css/picker.css'

import {
	login
} from '@/api/checket'
// Vue.use(lunduapi);
Vue.use(apiPlugin);
// Vue.prototype.$echartsGL = echartsGL
Vue.use(dataV)
Vue.use(ElementUI);
// import BaiduMap from 'vue-baidu-map'

// axios.defaults.baseURL = 'http://127.0.0.1:8888/api/'
//全局挂载echarts
Vue.prototype.$echarts = echarts
Vue.prototype.$http = axios
Vue.config.productionTip = false
const res = await login();
// console.log(res,"res");
Vue.prototype.$loginToken = res.data.data.accessToken;
// WS接口域名
Vue.prototype.$websocketApi = 'mt.ptzykjgs.com';


// 注册全局手机号过滤器

Vue.filter('format', function(phoneNumber) {
	if (typeof phoneNumber !== 'string') {
		return phoneNumber;
	}

	if (phoneNumber.length !== 11) {
		return phoneNumber;
	}

	return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
});

if (process.env.NODE_ENV === 'development') {
	Vue.config.devtools = true;
} else {
	Vue.config.devtools = false;
}
// Vue.use(BaiduMap, {
//   ak: 'TUKHAUDToorsGSaioepTzO0e9yazQ39a'
// })
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')