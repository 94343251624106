import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  count: 0
  },
  getters: {
	  //定义获取状态的方法
	  doubleCount(state) {
		  return state.count * 2;
	  }
  },
  mutations: {
	  // 定义修改状态的方法
	  increment(state) {
		  state.count++;
	  }
  },
  actions: {
  },
  modules: {
  }
})
