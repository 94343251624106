<template>
  <div id="app">
    <Adapter :isEnable="true">

      <router-view></router-view>
    </Adapter>

  </div>
</template>
<script>
import Adapter from './components/Adapter.vue';

export default{
 components:{
    Adapter,
}
}
</script>
<style lang="less">
body {
  background: #132134 !important;
  position: fixed;
  margin: 0;

}
</style>
