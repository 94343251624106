import Vue from 'vue'
import VueRouter from 'vue-router'





Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/map'

  },
  {
    path: '/left',
    component: () => import('@/components/left.vue')
  },

  {
    path: '/map',
    component: () => import('@/components/map.vue'),
    meta: {
      title: '智慧轮渡大数据平台'
    }

  },
  {
    path: '/header',
    component: () => import('@/components/Header.vue')

  },
  {
    path: '/right',
    component: () => import('@/components/right.vue')

  },
  {
    path: '/middle',
    component: () => import('@/components/middle.vue')

  },
  {
    path: '/acceptTrend',
    component: () => import('@/components/accpetTrend.vue')
  },
  {
    path: '/ageTrend',
    component: () => import('@/components/ageTrend.vue')
  },
  {
    path: '/test',
    component: () => import('@/components/test.vue')
  },
  {
    path: '/customerTrend',
    component: () => import('@/components/customerTrend.vue')
  },
  {
    path: '/getmoneyTrend',
    component: () => import('@/components/getmoneytrend.vue')
  },
  {
    path: '/carcountTrend',
    component: () => import('@/components/carcountTrend.vue')
  },
  {
    path: '/carpositionuse',
    component: () => import('@/components/carpositionuse.vue')
  },
  {
    path: '/warning',
    component: () => import('@/components/warning.vue')
  },
  
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
